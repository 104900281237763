<template>
  <div class="company-profile-ai-gen-ratings">
    <el-radio-group
      v-model="ratings">
      <el-radio-button
        :label="1">
        <!-- Note for dev: Combine these two icons and update them dynamically based on the button's status.-->
        <span
          v-if="rating == 1"
          class="material-icons active">thumb_up_alt</span>
        <span
          v-else
          class="material-icons">thumb_up_off_alt</span>
      </el-radio-button>
      <el-radio-button
        :label="-1">
        <!-- Note for dev: Combine these two icons and update them dynamically based on the button's status.-->
        <span
          v-if="rating == -1"
          class="material-icons active">thumb_down_alt</span>
        <span
          v-else
          class="material-icons">thumb_down_off_alt</span>
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  // @ts-ignore.
  // Note for Dev: This is for material icons. Move this if necessary for proper implemetation.
  head() {
    return {
      link: [
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/icon?family=Material+Icons'
        }
      ]
    }
  },
  name: 'GenAiRatings',
  props: {
    rating: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ratings: {
      get() {
        return this.rating
      },
      set(value) {
        this.$emit('update-rating', value)
      }
    }
  }
}
</script>
