export const companyProfileGenAiMixin = {
  computed: {
    isCompanyProfileGenAiEnabled() {
      const userSubscriptions = this.$store.state.userSubscriptions
      if (userSubscriptions.features.company_profile_genai) {
        return true
      }
      return false
    }
  }
}
