<template>
  <div class="company-profile-ai-gen">
    <div class="company-profile-ai-gen-header">
      AI-Generated Overview
      <el-popover
        placement="bottom"
        width="300"
        trigger="hover"
        popper-class="ai-gen-popover"
        content="AI-generated content may contain inaccurate information. Please verify the data with external sources. External links are provided for reference and are not affiliated with ImportGenius.">
        <el-button slot="reference"> <span class="gen-ai-icon" /></el-button>
      </el-popover>
    </div>
    <div
      v-loading="!profiles.Overview"
      class="company-profile-ai-gen-content">
      <div class="row no-gutters">
        <div class="col-md-7">
          <div 
            :class="{ 'ai-gen-content-clamp': showClass }"
            v-html="profiles.Overview"/>
          <button 
            class="btn-text" 
            @click="clickReadMore">
            <template v-if="showClass">
              Show more
            </template>
            <template v-else>
              Show less
            </template>
          </button>
          <gen-ai-ratings 
            :rating="ratings.rating"
            @update-rating="editRating" />
        </div>
        <div class="col-md-5 align-right">
          <button
            class="btn btn-default btn-primary mr-0"
            @click="clickShipmentTab" >
            Check shipment summary
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// @ts-ignore
import GenAiRatings from '~/components/common/GenAiRatings'
import { mapActions } from 'vuex'

export default {
  // @ts-ignore.
  // Replace or move this if necessary.
  head() {
    return {
      link: [
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/icon?family=Material+Icons'
        }
      ]
    }
  },
  name: 'OverviewAiGen',
  components: {
    GenAiRatings
  },
  props: {
    profiles: {
      type: Object,
      required: true
    },
    ratings: {
      type: Object,
      required: true
    }
  }, // This is a temporary name. Feel free to rename it accordingly.
  data() {
    return {
      showClass: true,
      showMoreText: 'Show more'
    }
  },
  methods: {
    ...mapActions('company', ['editRating']),
    clickShipmentTab() {
      const shipmentLink = Array.from(document.querySelectorAll('a')).find(
        link => link.textContent.trim() === 'Shipments'
      )

      if (shipmentLink) {
        shipmentLink.click()
      }
    },
    clickReadMore() {
      this.showClass = !this.showClass
    }
  }
}
</script>
